.assessment-footer {
    background-color: #232f3e;
    margin-top: 2px;
    .max-width-container {
        box-sizing: initial;
        padding: 0 4rem;
    }
    .footer-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
        color: rgb(213, 219, 219);

        ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
            row-gap: 16px;

            li {
                margin-right: 16px;
                a {
                    color: rgb(213, 219, 219) !important;
                    font-family: 'Amazon Ember', 'Helvetica Neue', Roboto, Arial, sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    text-decoration: none;
                    padding-right: 14px;
                }
            }
        }
        .footer-body-copyright {
            float: right;
            p {
                color: rgb(213, 219, 219) !important;
            }
        }
    }
}
